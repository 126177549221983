



































import { UploadFile } from "ant-design-vue/types/upload";
import { Component, Vue } from "vue-property-decorator";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Api } from "@/models/class/api.class";
@Component({
  mixins: [MNotificationVue],
})
export default class UploadInternalContract extends Vue {
  Api = Api;
  headers = {
    authorization: "Bearer " + this.$store.state.access_token,
  };
  form = this.$form.createForm(this, { name: "uploadInternalContract" });
  fileList: UploadFile[] = [];
  beforeUpload(file) {
    const isLt5M = file.size;
    if (isLt5M >= 5242880) {
      this.showNotifError("lbl_message_file_size_exceeded", {
        fileSize: 5,
      });
    }
    return isLt5M;
  }
  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }
  handleChange(info) {
    let fileList = [...info.fileList];
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.fileList = fileList;

    const status = info.file.status;
    if (status === "done") {
      this.$message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      this.uploadProses = true;
      this.$message.error(`${info.file.name} file upload failed.`);
      this.$message.error(`${info.file.response?.details}`);
    }
  }

  get formItemLayout() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
  }
}
